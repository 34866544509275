import React, {useState} from 'react';
import axios from 'axios';
import {config} from "./config";

function App() {
    const [prompt, setPrompt] = useState('');
    const [chatHistory, setChatHistory] = useState([]);

    const handleSubmit = async () => {
        setPrompt('');
        let history = [...chatHistory];
        history = await handleUserInput(history);
        chatScroll();
        try {
            const res = await axios.post('https://web.alexhinterleitner.com:4001/api/openai', { prompt });
            await handleGPTMessage(history, 'GPT', res.data.message.content);
        } catch (error) {
            console.log('Fehler bei der Anfrage', error);
            await handleGPTMessage(history, 'ERROR', error.message);
        }
        chatScroll();
    };

    const chatScroll = () => {
        const chat__messagearea = document.getElementById('chat__messagearea');
        if(chat__messagearea) {
            setTimeout(() => {
                chat__messagearea.scrollTop = chat__messagearea.scrollHeight;
            },200)
        }
    }

    const handleUserInput = (history) => {
        history.push({
            role: 'User',
            message: prompt,
        }, {
            role: 'GPT_loading',
            message: '',
        });
        console.log(history);
        setChatHistory(history);
        return history;
    }

    const handleGPTMessage = (history, role, message) => {
        // console.log(1, history);
        history = history.filter(item => item.role !== 'GPT_loading');
        // console.log(2, history);
        history.push({
            role,
            message,
        })
        // console.log(3, history);
        setChatHistory(history);
    }

    const handleChatEnter = (e) => {
        if(!config.isMobile.any()) {
            if(e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                if(prompt.trim() !== '') {
                    handleSubmit().then(() => {
                        setPrompt('');
                    });
                }
            }
        }
    }

    return (
        <div className="chat">
            <div className="chat__messagearea" id="chat__messagearea">
                {chatHistory.map((item) => (
                    <div className={"chat__message chat__message__wrapper--" + item.role}>
                        <div className={"chat__message__role chat__message--" + item.role}>{item.role !== 'GPT_loading' && item.role}</div>
                        <div className={"chat__message__message chat__message--" + item.role}>{item.message}</div>
                    </div>
                ))}
            </div>
            <div className="chat__inputarea">
                <textarea value={prompt} onChange={(e) => setPrompt(e.target.value)} onKeyDown={(e) => handleChatEnter(e)} />
                <button onClick={handleSubmit}>Senden</button>
            </div>
        </div>
    );
}

export default App;
